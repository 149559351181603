<template>
  <div class="h-screen flex items-center justify-center overflow-hidden">
    <div class="relative flex w-full h-full bg-black overflow-hidden" style="max-width: 500px; max-height: 500px">
      <div id="canvas" class="items-middle">
        <!-- base image -->
        <img :src="main_image_url" @load="_set_image_loading_state(false)" alt="" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain" style="z-index: 0" />
      </div>
      <!-- version toggole -->
      <div class="absolute top-2 right-2" style="z-index: 100">
        <div class="relative">
          <div class="flex flex-row-reverse items-center justify-end">
            <button @click="_toggleVersionDropDown" class="flex flex-col items-center rounded px-3 bg-gray-700 rounded">
              <p class="font-body font-bold text-sm text-white">
                {{ selected_version }}
              </p>
            </button>
            <svg v-if="is_image_loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div v-if="versionDropDown" class="absolute right-0 mt-1 shadow-lg bg-gray-700 rounded z-20">
            <div class="flex flex-col items-center justify-center gap-y-1 py-2 px-3">
              <button @click="_updateVersionSelection(index)" v-for="(version, index) in versions" :key="index" class="relative w-full h-8 group">
                <div class="flex flex-row items-center justify-center whitespace-nowrap" :class="index == selected_version_index ? 'text-rug-green' : 'text-white'">
                  <div class="font-body font-bold text-sm text-center align-middle text-neutral-content">
                    {{ version.name }}
                  </div>
                </div>
                <!-- version description -->
                <div v-if="version.description" class="absolute top-0 right-12 bg-gray-700 rounded p-2 mr-1 font-body text-xs text-primary hidden group-hover:block">
                  {{ version.description }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- save image -->
      <div class="absolute bottom-2 right-2" style="z-index: 100">
        <button @click="copy_image_download_url" class="bg-gray-700 rounded pb-1 px-1">
          <img src="@/assets/icons/download-icon.svg" alt="" class="object-contain h-6" />
        </button>
      </div>
      <div class="absolute bottom-2 right-12 left-12 flex items-end justify-end" style="z-index: 100">
        <div v-if="show_download_message" class="font-body font-medium text-right text-xs bg-gray-700 text-primary rounded px-3 py-2">{{ download_message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import versions from '@/assets/data/versions.json'
import image_hashes from '@/assets/data/image_hash.json'

// import html2canvas from 'html2canvas'
// import { saveAs } from 'file-saver'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      item_id: this.$route.params.id,
      // versions
      versionDropDown: false,
      versions: versions,
      selected_version_index: null,
      is_image_loading: false,
      // download image
      metadata_url: `https://rugwtf.s3.amazonaws.com/metadata/iframe/1.0.0/${this.$route.params.id}.json`,
      item_metadata: null,
      item_sku: null,
      download_message: null,
      show_download_message: false,
    }
  },
  computed: {
    selected_version() {
      if (!this.selected_version_index) {
        this.selected_version_index = 0
      }
      return this.versions[this.selected_version_index].name
    },
    selected_version_value() {
      if (!this.selected_version_index) {
        this.selected_version_index = 0
      }
      return this.versions[this.selected_version_index].value
    },
    main_image_url() {
      if (this.selected_version_value == '0.0.0') {
        return `https://rugwtf.s3.us-east-1.amazonaws.com/500/${this.selected_version_value}/all.gif`
      } else {
        return `https://rugwtf.s3.us-east-1.amazonaws.com/500/${this.selected_version_value}/${this.item_id}.png`
      }
    },
    download_image_url() {
      let selected_version_data = this.versions[this.selected_version_index]
      let image_hash = image_hashes[selected_version_data.value]
      if (selected_version_data.download_type == 'id') {
        return `https://gateway.pinata.cloud/ipfs/${image_hash}/${this.item_id}.png`
      } else if (selected_version_data.download_type == 'aws') {
        return this.main_image_url
      } else {
        // sku download
        return `https://gateway.pinata.cloud/ipfs/${image_hash}/${this.item_sku}.png`
      }
    },
  },
  async created() {
    this.get_metadata()
  },
  async mounted() {},
  methods: {
    _toggleVersionDropDown() {
      this.customsDropDown = false
      this.versionDropDown = !this.versionDropDown
    },
    _updateVersionSelection(index) {
      this.selected_version_index = index
      this._set_image_loading_state(true)
      this._toggleVersionDropDown()
    },
    _set_image_loading_state(bool) {
      this.is_image_loading = bool
    },
    async get_metadata() {
      await axios
        .get(this.metadata_url)
        .then((response) => {
          this.item_metadata = response.data
          this.item_sku = this.item_metadata.sku
        })
        .catch((error) => {
          console.log(error)
        })
    },
    _toggle_show_download_message(bool, message) {
      this.download_message = message
      this.show_download_message = bool
    },
    async _save_image() {},
    copy_image_download_url() {
      var image_download_el = document.createElement('textarea')
      image_download_el.value = this.download_image_url
      image_download_el.style.top = '0'
      image_download_el.style.left = '0'
      image_download_el.style.position = 'fixed'
      document.body.appendChild(image_download_el)
      image_download_el.focus()
      image_download_el.select()
      try {
        let success = document.execCommand('copy')
        this._toggle_show_download_message(true, 'Image link copied!')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
      } catch (error) {
        this._toggle_show_download_message(true, 'Could not copy download image url')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
        console.log(error)
      }
      document.body.removeChild(image_download_el)

      // iframe restricted
      // if (!navigator.clipboard) {
      //   this._toggle_show_download_message(true, 'Could not copy download image url')
      //   setTimeout(() => {
      //     this._toggle_show_download_message(false, null)
      //   }, 1500)
      //   console.error('Could not copy image download link: no navigator found')
      //   return
      // }
      // navigator.clipboard
      //   .writeText(this.download_image_url)
      //   .then(() => {
      //     this._toggle_show_download_message(true, 'Image link copied!')
      //     setTimeout(() => {
      //       this._toggle_show_download_message(false, null)
      //     }, 1500)
      //   })
      //   .catch((err) => {
      //     this._toggle_show_download_message(true, 'Could not copy download image url')
      //     console.error('Could not copy image download link: ', err)
      //     setTimeout(() => {
      //       this._toggle_show_download_message(false, null)
      //     }, 1500)
      //   })
    },
  },
}
</script>

<style scoped>
/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  background: transparent; /* make scrollbar transparent */
}
</style>
